export const daysUntil = (date) => {
  const currentDate = new Date();
  const targetDate = new Date(date);

  const timeDifference = targetDate - currentDate;

  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference > 0 ? daysDifference : 0;
};

export const get2YearsFromNow = () => {
  const today = new Date();
  const futureDate = new Date(today.setFullYear(today.getFullYear() + 2));

  const year = futureDate.getFullYear();
  const month = String(futureDate.getMonth() + 1).padStart(2, '0');
  const day = String(futureDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const dateIsPast = (date) => {
  const today = new Date();
  if (today > date) return true;
  return false;
};
