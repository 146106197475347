//import API from 'assets/icons/API.svg';
import Copy from 'assets/icons/Copy.svg';
import Dashboard from 'assets/icons/Dashboard.svg';
import Flow from 'assets/icons/Flow.svg';
import Portfolio from 'assets/icons/Portfolio.svg';
import Settings from 'assets/icons/Settings.svg';
//import Subscriptions from 'assets/icons/Subscriptions.svg';
import logo from 'assets/logo.png';
import SidebarItem from 'components/Sidebar/SidebarItem/SidebarItem';
import { WorkspaceContext } from 'context/WorkspaceContext';
import { isOwner, isProvider } from 'helpers/auth.helpers';
import { useContext } from 'react';

import './Sidebar.scss';

function Sidebar() {
  const { workspace } = useContext(WorkspaceContext);

  return (
    <div className="sidebar" data-testid="sidebar">
      <a href="/dashboard">
        <img className="logo" src={logo} alt="Appraisal Bureau Logo" />
      </a>
      <SidebarItem
        text="Dashboard"
        icon={Dashboard}
        linkDestination={'dashboard'}
      />
      {isOwner(workspace) && (
        <>
          <SidebarItem
            text="Portfolio"
            icon={Portfolio}
            linkDestination={'portfolio'}
          />
          <SidebarItem
            text="Shared Queue"
            icon={null}
            linkDestination={'/portfolio/shared-queue'}
          />
          <SidebarItem
            text="Workflows"
            icon={Flow}
            linkDestination={'workflows'}
          />
          <SidebarItem
            text="Documents"
            icon={Copy}
            linkDestination={'/document-center'}
          />
        </>
      )}
      {isProvider(workspace) && (
        <>
          <SidebarItem
            text="Portfolio"
            icon={Portfolio}
            linkDestination={'/portfolio'}
          />
          <SidebarItem
            text="Shared Queue"
            icon={null}
            linkDestination={'/portfolio/shared-queue'}
          />
        </>
      )}
      <div className="bottom-group">
        <hr className="divider" />
        <SidebarItem
          text="Settings"
          icon={Settings}
          linkDestination={'settings'}
        />
      </div>
    </div>
  );
}

export default Sidebar;
