export const calculatePortfolioTotal = (portfolio) => {
  let total = 0;
  for (let i = 0; i < portfolio.length; i++) {
    total += portfolio[i].value;
  }
  return total;
};

export const addToFilterObj = (obj, keys, value) => {
  if (keys.length === 0) {
    Object.assign(obj, value);
    return obj;
  }
  keys.reduce((acc, key, idx) => {
    if (idx === keys.length - 1) {
      if (acc[key]) {
        if (Array.isArray(acc[key])) {
          acc[key].push(value);
        } else {
          acc[key] = [acc[key], value];
        }
      } else {
        acc[key] = value;
      }
    } else {
      acc[key] = acc[key] || {};
    }
    return acc[key];
  }, obj);
};

const handleItemField = (filter, filterObj, key) => {
  const keys = ['description', 'location', 'date_acquired'];
  const combinedFilters = keys.reduce(
    (acc, key) => acc.concat(filter[key] || []),
    [],
  );
  if (combinedFilters.length === 1) {
    const item = combinedFilters[0];
    const path = [key, item.selector];
    addToFilterObj(filterObj, path, item.query);
  } else {
    filter[key].forEach((item, index) => {
      const path = ['$or', index.toString(), key, item.selector];
      addToFilterObj(filterObj, path, item.query);
    });
  }
};

const handleCollection = (filter, filterObj, key) => {
  filterObj.tags = filterObj.tags || {
    $and: [{ key: { $eqi: 'collection' } }],
  };
  if (filter[key].length === 1) {
    const item = filter[key][0];
    const path = ['tags', '$and', 1, 'value', item.selector];
    addToFilterObj(filterObj, path, item.query);
  } else {
    filter[key].forEach((item, index) => {
      const path = [
        'tags',
        '$and',
        1,
        '$or',
        index.toString(),
        'value',
        item.selector,
      ];
      addToFilterObj(filterObj, path, item.query);
    });
  }
};

export const handleArtist = (filter, filterObj, key) => {
  if (filter[key].length === 1) {
    const item = filter[key][0];
    const artistName = item.query;
    const artistQuery = {
      full_name: artistName,
    };
    filterObj.artwork = { ...filterObj.artwork, artist: artistQuery };
  } else {
    filter[key].forEach((item, index) => {
      const artistName = item.query;
      const artistQuery = {
        full_name: artistName,
      };
      if (filterObj.artwork?.artist?.$or) {
        filterObj.artwork.artist.$or.push(artistQuery);
      } else if (filterObj.artwork?.artist?.$and) {
        filterObj.artwork.artist = {
          $or: [filterObj.artwork.artist.$and, artistQuery],
        };
      } else {
        filterObj.artwork = {
          ...filterObj.artwork,
          artist: { $or: [artistQuery] },
        };
      }
    });
  }
};

const handleUser = (filter, filterObj) => {
  if (filter['user'].length === 1) {
    const item = filter['user'][0];
    const userQuery = {
      name_first: {
        $containsi: item.query,
      },
    };
    addToFilterObj(filterObj, ['subscription_workspace', 'user'], userQuery);
  } else {
    filter['user'].forEach((item, index) => {
      const userQuery = {
        name_first: {
          $containsi: item.query,
        },
      };
      const path = ['subscription_workspace', 'user', '$or', index.toString()];
      addToFilterObj(filterObj, path, userQuery);
    });
  }
};

export const handleArtworkField = (filter, filterObj, key) => {
  const keys = [
    'title',
    'year',
    'provenance',
    'size_width',
    'size_height',
    'condition',
    'medium',
    'dims',
  ];
  const combinedFilters = keys.reduce(
    (acc, key) => acc.concat(filter[key] || []),
    [],
  );
  const numberFields = ['year', 'size_width', 'size_height'];
  if (combinedFilters.length === 1) {
    const item = combinedFilters[0];
    let itemQuery = item.query;
    if (numberFields.includes(key)) {
      if (Array.isArray(itemQuery)) {
        itemQuery = [Number(itemQuery[0]), Number(itemQuery[1])];
      } else {
        itemQuery = Number(itemQuery);
      }
    }
    const path = ['artwork', key, item.selector];
    addToFilterObj(filterObj, path, itemQuery);
  } else {
    filter[key].forEach((item, index) => {
      let itemQuery = item.query;
      if (numberFields.includes(key)) {
        if (Array.isArray(itemQuery)) {
          itemQuery = [Number(itemQuery[0]), Number(itemQuery[1])];
        } else {
          itemQuery = Number(itemQuery);
        }
      }
      const path = ['artwork', '$or', index.toString(), key, item.selector];
      addToFilterObj(filterObj, path, itemQuery);
    });
  }
};

const handleValuationField = (filter, filterObj, key) => {
  const keys = ['fmv', 'rrv', 'lastReport'];
  const numberFields = ['fmv', 'rrv'];
  const combinedFilters = keys.reduce(
    (acc, key) => acc.concat(filter[key] || []),
    [],
  );
  if (combinedFilters.length === 1) {
    const item = combinedFilters[0];
    let pathKey = '';
    if (key === 'lastReport') {
      pathKey = 'date_effective';
    } else if (key === 'fmv') {
      pathKey = 'value_fmv';
    } else if (key === 'rrv') {
      pathKey = 'value_rrv';
    }
    let itemQuery = item.query;
    if (numberFields.includes(key)) {
      if (Array.isArray(itemQuery)) {
        itemQuery = [Number(itemQuery[0]), Number(itemQuery[1])];
      } else {
        itemQuery = Number(itemQuery);
      }
    }
    const path = ['appraisals', 'valuation', pathKey, item.selector];
    addToFilterObj(filterObj, path, itemQuery);
  } else {
    filter[key].forEach((item, index) => {
      let pathKey = '';
      if (key === 'lastReport') {
        pathKey = 'date_effective';
      } else if (key === 'fmv') {
        pathKey = 'value_fmv';
      } else if (key === 'rrv') {
        pathKey = 'value_rrv';
      }
      let itemQuery = item.query;
      if (numberFields.includes(key)) {
        if (Array.isArray(itemQuery)) {
          itemQuery = [Number(itemQuery[0]), Number(itemQuery[1])];
        } else {
          itemQuery = Number(itemQuery);
        }
      }
      const path = [
        'appraisals',
        'valuation',
        '$or',
        index.toString(),
        pathKey,
        item.selector,
      ];
      addToFilterObj(filterObj, path, itemQuery);
    });
  }
};

export const formatFilterAsUserFilter = (filter) => {
  let values = [];
  for (const key in filter) {
    if (filter[key].length > 0) {
      for (let i = 0; i < filter[key].length; i++) {
        values.push({
          type: toBackendKey(key),
          value: filter[key][i],
        });
      }
    }
  }
  return values;
};

export const formatFilterForQuery = (filter) => {
  let filterObj = {};

  for (const key in filter) {
    if (filter[key].length > 0) {
      if (
        ['description', 'location', 'date_acquired', 'condition'].includes(
          key,
        ) &&
        !Object.keys(filterObj).includes(key)
      ) {
        handleItemField(filter, filterObj, key);
      } else if (key === 'collection') {
        handleCollection(filter, filterObj, key);
      } else if (key === 'artist') {
        handleArtist(filter, filterObj, key);
      } else if (
        [
          'title',
          'year',
          'provenance',
          'size_width',
          'size_height',
          'medium',
        ].includes(key) &&
        !Object.keys(filterObj).includes(key)
      ) {
        handleArtworkField(filter, filterObj, key);
      } else if (['fmv', 'rrv', 'lastReport'].includes(key)) {
        handleValuationField(filter, filterObj, key);
      } else if (key === 'user') {
        handleUser(filter, filterObj);
      }
    }
  }
  return filterObj;
};

export const FILTER_KEY_MAP = {
  // Frontend to Backend
  lastReport: 'DATE_APPRAISED',
  collection: 'ITEM_TAG',
  size_height: 'HEIGHT',
  size_width: 'WIDTH',
  size_units: 'UNITS',
  // add other mappings...
};

// Create reverse mapping (Backend to Frontend)
export const REVERSE_FILTER_KEY_MAP = Object.entries(FILTER_KEY_MAP).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [value]: key,
  }),
  {},
);

export const toBackendKey = (frontendKey) => {
  if (FILTER_KEY_MAP[frontendKey]) {
    return FILTER_KEY_MAP[frontendKey];
  }
  return frontendKey.toUpperCase();
};

export const toFrontendKey = (backendKey) => {
  if (REVERSE_FILTER_KEY_MAP[backendKey]) {
    return REVERSE_FILTER_KEY_MAP[backendKey];
  }
  return backendKey.toLowerCase();
};
