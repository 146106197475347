import EmailConfirmation from 'components/EmailConfirmation/EmailConfirmation';
import Navbar from 'components/Navbar/Navbar';
import Page from 'components/Page/Page';
import SettingsSidebar from 'components/Sidebar/SettingsSidebar';
import Sidebar from 'components/Sidebar/Sidebar';
import { useAuthContext } from 'context/AuthContext';
import { RoleContext } from 'context/RoleContext';
import { WorkspaceContext } from 'context/WorkspaceContext';
import {
  getToken,
  isAppraiser,
  isOrgAdmin,
  isOwner,
  isProvider,
  isWorkspaceManager,
} from 'helpers/auth.helpers';
import React, { useContext } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import './App.scss';

const Dashboard = React.lazy(() => import('pages/Dashboard/Dashboard'));
const Portfolio = React.lazy(() => import('pages/Portfolio/Portfolio'));
const Login = React.lazy(() => import('pages/Login/Login'));
const Register = React.lazy(() => import('pages/Register/Register'));
const ArtworkPage = React.lazy(() => import('pages/ArtworkPage/ArtworkPage'));
const ApiKeys = React.lazy(() => import('pages/ApiKeys/ApiKeys'));
const UserSettings = React.lazy(
  () => import('pages/UserSettings/UserSettings'),
);
const OrganizationSettings = React.lazy(
  () => import('pages/OrganizationSettings/OrganizationSettings'),
);
const WorkspaceSettings = React.lazy(
  () => import('pages/WorkspaceSettings/WorkspaceSettings'),
);
const DocumentCenter = React.lazy(
  () => import('pages/DocumentCenter/DocumentCenter'),
);
const Subscriptions = React.lazy(
  () => import('pages/Subscriptions/Subscriptions'),
);
const Workflows = React.lazy(() => import('pages/Workflows/Workflows'));
const AddArtwork = React.lazy(() => import('pages/AddArtwork/AddArtwork'));
const StartDonation = React.lazy(
  () => import('pages/StartDonation/StartDonation'),
);
const CreateCollection = React.lazy(
  () => import('pages/CreateCollection/CreateCollection'),
);
const AddSale = React.lazy(() => import('pages/AddSale/AddSale'));
const ValidateData = React.lazy(
  () => import('pages/ValidateData/ValidateData'),
);
const AppraisalSummary = React.lazy(
  () => import('pages/AppraisalSummary/AppraisalSummary'),
);
const EditPage = React.lazy(() => import('pages/EditPage/EditPage'));
const OnboardOrganization = React.lazy(
  () => import('pages/OnboardOrganization/OnboardOrganization'),
);
const ResetPassword = React.lazy(
  () => import('pages/ResetPassword/ResetPassword'),
);
const ForgotPassword = React.lazy(
  () => import('pages/ForgotPassword/ForgotPassword'),
);
const OnboardWorkspace = React.lazy(
  () => import('pages/OnboardWorkspace/OnboardWorkspace'),
);

const ResendConfirmationEmail = React.lazy(
  () => import('pages/ResendConfirmationEmail/ResendConfirmationEmail'),
);
const AppraiserDashboard = React.lazy(
  () => import('pages/AppraiserDashboard/AppraiserDashboard'),
);
const SharedItems = React.lazy(() => import('pages/SharedItems/SharedItems'));
const ClientPage = React.lazy(() => import('pages/ClientPage/ClientPage'));

function App() {
  const location = useLocation();
  const { isLoading, appraiserMode } = useAuthContext();
  const { roles } = useContext(RoleContext);
  const { workspace } = useContext(WorkspaceContext);

  const wrapPrivateRoute = (element) => {
    if (isLoading) {
      return null;
    }
    if (getToken()) {
      return element;
    } else {
      return <Navigate to="/login" replace />;
    }
  };
  const AuthorizedUserLayout = () => (
    <Page>
      {!location.pathname.includes('onboard') ? <Navbar /> : null}
      {!appraiserMode && !location.pathname.includes('onboard') ? (
        location.pathname.includes('settings') ? (
          <SettingsSidebar />
        ) : (
          <Sidebar />
        )
      ) : null}
      <div
        className={`content-container ${
          !appraiserMode && 'authorized-layout'
        } ${location.pathname.includes('onboard') ? 'onboard-layout' : ''}`}
        data-testid="content-container"
        style={location.pathname.includes('onboard') ? { marginLeft: 0 } : {}}
      >
        <Outlet />
      </div>
    </Page>
  );

  return (
    <>
      <Routes>
        <Route
          path=""
          element={
            getToken() ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/register"
          element={
            getToken() ? <Navigate to="/dashboard" replace /> : <Register />
          }
        />
        <Route
          path="/login"
          element={
            getToken() ? <Navigate to="/dashboard" replace /> : <Login />
          }
        />
        <Route path="/email-confirmation" element={<EmailConfirmation />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/resend-confirmation-email"
          element={<ResendConfirmationEmail />}
        />

        <Route element={wrapPrivateRoute(<AuthorizedUserLayout />)}>
          <Route
            path="/dashboard"
            element={appraiserMode ? <AppraiserDashboard /> : <Dashboard />}
          />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/:id" element={<ArtworkPage />} />
          <Route
            path="/settings"
            element={<Navigate to="/settings/user" replace />}
          />
          <Route path="/settings/user" element={<UserSettings />} />
          {isOrgAdmin(roles) && (
            <Route
              path="/settings/organization"
              element={<OrganizationSettings />}
            />
          )}
          {isWorkspaceManager(roles) && (
            <Route path="/settings/workspace" element={<WorkspaceSettings />} />
          )}
          <Route
            path="/onboard-organization"
            element={<OnboardOrganization />}
          />
          <Route path="/onboard-workspace" element={<OnboardWorkspace />} />

          {!appraiserMode && (
            <>
              <Route path="/document-center" element={<DocumentCenter />} />
              {isProvider(workspace) && (
                <>
                  <Route
                    path="/portfolio/shared-queue"
                    element={<SharedItems />}
                  />
                  <Route
                    path="/portfolio/clients/:type/:id"
                    element={<ClientPage />}
                  />
                </>
              )}
              {isOwner(workspace) && (
                <>
                  <Route
                    path="/portfolio/shared-queue"
                    element={<SharedItems />}
                  />

                  <Route path="/subscriptions" element={<Subscriptions />} />
                  <Route path="/workflows" element={<Workflows />} />
                  <Route
                    path="/workflows/add-artwork"
                    element={<AddArtwork />}
                  />
                  <Route path="/documents" element={<DocumentCenter />} />
                  <Route path="/api-keys" element={<ApiKeys />} />
                  <Route path="/start-donation" element={<StartDonation />} />
                  <Route
                    path="/create-collection"
                    element={<CreateCollection />}
                  />
                </>
              )}
            </>
          )}
          {isAppraiser() && appraiserMode && (
            <>
              <Route path="/appraisals/:id" element={<AppraisalSummary />} />
              <Route path="/add-sale" element={<AddSale />} />
              <Route path="/validate-data" element={<ValidateData />} />
              <Route
                path="/validate-data/edit-artwork/:id"
                element={<EditPage type="artwork" />}
              />
              <Route
                path="/validate-data/edit-artist/:id"
                element={<EditPage type="artist" />}
              />
            </>
          )}
        </Route>
      </Routes>
    </>
  );
}

export default App;
