import { ThemeProvider } from '@mui/material/styles';
import App from 'components/App/App';
import { AuthProvider } from 'context/AuthContext';
import { FallbackProvider } from 'context/FallbackContext';
import { RoleProvider } from 'context/RoleContext';
import { WorkspaceProvider } from 'context/WorkspaceContext';
import 'nprogress/nprogress.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'styles/index.scss';
import { theme } from 'styles/theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <BrowserRouter>
        <FallbackProvider>
          <WorkspaceProvider>
            <RoleProvider>
              <App />
            </RoleProvider>
          </WorkspaceProvider>
        </FallbackProvider>
      </BrowserRouter>
    </AuthProvider>
  </ThemeProvider>,
);
