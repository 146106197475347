export const parseSessionStorage = (item, defaultValue) => {
  const sessionItem = sessionStorage.getItem(item);
  try {
    const sessionJson = JSON.parse(sessionItem);
    if (sessionJson !== null) {
      return sessionJson;
    } else {
      return defaultValue;
    }
  } catch (error) {
    return defaultValue;
  }
};
