import { message } from 'antd';
import { getLoggedInUser } from 'api/users';
import { getToken, isAppraiser, logout } from 'helpers/auth.helpers';
import { parseSessionStorage } from 'helpers/session.helpers';
import { createContext, useContext } from 'react';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export const AuthContext = createContext({
  user: undefined,
  isLoading: false,
  setUser: () => {},
  appraiserMode: false,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [appraiserMode, setAppraiserMode] = useState(
    parseSessionStorage('appraiserMode', false),
  );
  const authToken = getToken();

  useEffect(() => {
    if (userData && !isAppraiser()) {
      setAppraiserMode(false);
    }
  }, [userData]);

  useEffect(() => {
    sessionStorage.setItem('appraiserMode', appraiserMode);
  }, [appraiserMode]);

  const fetchLoggedInUser = async () => {
    setIsLoading(true);
    try {
      const data = await getLoggedInUser({
        params: { populate: { avatar: '*' } },
      });
      setUserData(data);
    } catch (error) {
      message.error('Error while getting logged in user details');
      logout();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUser = (user) => {
    setUserData(user);
  };

  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser();
    }
  }, [authToken]);

  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      <AuthContext.Provider
        value={{
          user: userData,
          setUser: handleUser,
          isLoading,
          appraiserMode,
          setAppraiserMode,
        }}
      >
        {children}
      </AuthContext.Provider>
    </ErrorBoundary>
  );
};
