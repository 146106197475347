import logo from 'assets/logo.png';
import SidebarItem from 'components/Sidebar/SidebarItem/SidebarItem';
import { RoleContext } from 'context/RoleContext';
import { isOrgAdmin, isWorkspaceManager } from 'helpers/auth.helpers';
import { useContext } from 'react';

import './Sidebar.scss';

function SettingsSidebar() {
  const { roles } = useContext(RoleContext);
  return (
    <div className="sidebar settings-sidebar" data-testid="settings-sidebar">
      <a href="/dashboard">
        <img className="logo" src={logo} alt="Appraisal Bureau Logo" />
      </a>
      <SidebarItem
        text="User Profile Settings"
        icon={null}
        linkDestination={'/settings/user'}
      />
      {isOrgAdmin(roles) && (
        <SidebarItem
          text="Organization Settings"
          icon={null}
          linkDestination={'/settings/organization'}
        />
      )}
      {isWorkspaceManager(roles) && (
        <SidebarItem
          text="Workspace Settings"
          icon={null}
          linkDestination={'/settings/workspace'}
        />
      )}
    </div>
  );
}

export default SettingsSidebar;
