import apiClient from 'services/apiService';

export const putUser = (id, data, params) => {
  return apiClient.put(`/users/${id}`, data, params);
};

export const getLoggedInUser = (params) => {
  return apiClient.get('/users/me', params).then((response) => response.data);
};

export const createUser = (data) => {
  return apiClient.post('/users', data).then((response) => response.data);
};
