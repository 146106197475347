import { getAccessOrganizations, getAccessWorkspaces } from 'api/access';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { WorkspaceContext } from './WorkspaceContext';

export const RoleContext = createContext({
  roles: {
    organization: null,
    workspace: null,
  },
});

export const RoleProvider = ({ children }) => {
  const { workspace } = useContext(WorkspaceContext);
  const [roles, setRoles] = useState({ organization: null, workspace: null });

  const getWorkspaceRole = useCallback(async () => {
    try {
      if (workspace && workspace.id) {
        const accessItems = await getAccessWorkspaces({
          params: { populate: '*', filters: { item: workspace.id } },
        });
        if (accessItems.data?.length > 0) {
          const accessRole =
            accessItems.data[0].attributes?.access_role?.data?.id || 0;
          setRoles((prev) => ({ ...prev, workspace: accessRole }));
        }
      }
    } catch (error) {
      console.error('Failed to fetch workspace role', error);
    }
  }, [workspace]);

  const getOrganizationRole = useCallback(async () => {
    try {
      if (workspace && workspace.organization_id) {
        const accessItems = await getAccessOrganizations({
          params: {
            populate: '*',
            filters: {
              item: workspace.organization_id,
            },
          },
        });
        if (accessItems.data?.length > 0) {
          const accessRole =
            accessItems.data[0].attributes?.access_role?.data?.id || 0;
          setRoles((prev) => ({ ...prev, organization: accessRole }));
        }
      }
    } catch (error) {
      console.error('Failed to fetch organization role', error);
    }
  }, [workspace]);

  useEffect(() => {
    if (workspace && workspace.id) {
      getWorkspaceRole();
      getOrganizationRole();
    }
  }, [workspace, getWorkspaceRole, getOrganizationRole]);

  return (
    <RoleContext.Provider value={{ roles }}>{children}</RoleContext.Provider>
  );
};
