import { decodeJwt } from 'jose';
import Cookies from 'js-cookie';

export const setToken = (token) => {
  if (token) {
    Cookies.set('jwt', token, { secure: true, sameSite: 'none' });
  }
};

export const removeToken = () => {
  Cookies.remove('jwt');
};

export const getAllCookies = () => {
  return Cookies.get();
};

export const getToken = () => {
  return Cookies.get('jwt');
};

export const displayUserInfo = (user) => {
  if (user === undefined || user === null) {
    return '';
  }
  if (user.name_first !== null && user.name_last !== null) {
    return `${user.name_first} ${user.name_last}`;
  } else if (user.username !== null) {
    return user.username;
  } else {
    return user.email;
  }
};

export const logout = () => {
  sessionStorage.clear();
  removeToken();
};

export const isOwner = (workspace) => {
  if (workspace === undefined || workspace === null) {
    return false;
  }
  return workspace.organization_type === 'OWNER';
};

export const isProvider = (workspace) => {
  if (workspace === undefined || workspace === null) return false;
  return workspace.organization_type === 'PROVIDER';
};

export const isAppraiser = () => {
  const token = getToken();
  if (!token) return false;

  try {
    const payload = decodeJwt(token);
    return payload.role === 'appraiser';
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return false;
  }
};

export const isAuthenticated = () => {
  const token = getToken();
  if (!token) return false;

  try {
    const payload = decodeJwt(token);
    return payload.role === 'authenticated';
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return false;
  }
};

export const isOrgAdmin = (roles) => {
  return roles.organization === 4;
};

export const isWorkspaceManager = (roles) => {
  return roles.workspace === 1;
};
