const { Navigate, useLocation } = require('react-router-dom');

const EmailConfirmation = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  return (
    <Navigate to="/register" replace state={{ formStep: 5, code: code }} />
  );
};

export default EmailConfirmation;
