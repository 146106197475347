import { ClickAwayListener } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ChevronDown from 'assets/icons/ChevronDown.svg';
import ChevronUp from 'assets/icons/ChevronUp.svg';
import { useAuthContext } from 'context/AuthContext';
import { WorkspaceContext } from 'context/WorkspaceContext';
import { isAppraiser, isOwner, logout } from 'helpers/auth.helpers';
import { displayUserInfo } from 'helpers/auth.helpers';
import { useCallback, useContext, useMemo, useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import './Navbar.scss';

function Navbar() {
  const { user, setUser, appraiserMode, setAppraiserMode } = useAuthContext();
  const { workspace, setWorkspace, workspaces } = useContext(WorkspaceContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState({
    left: false,
    right: false,
    hamburger: false,
  });

  let currentLocation = useLocation().pathname.substring(1);

  const toggleAccordion = (accordion) => {
    setExpanded((prev) => ({
      ...prev,
      [accordion]: !prev[accordion],
    }));
  };

  const closeAccordion = useCallback((accordion) => {
    setExpanded((prev) => ({
      ...prev,
      [accordion]: false,
    }));
  }, []);

  const organizations = useMemo(() => {
    return workspaces.reduce((acc, ws) => {
      const org = ws.organization_name;
      if (!acc[org]) {
        acc[org] = [];
      }
      acc[org].push(ws);

      const isCurrentWorkspaceInOrg = acc[org].some(
        (workspaceItem) => workspaceItem.name === workspace?.name,
      );
      if (isCurrentWorkspaceInOrg) {
        setExpanded((prev) => ({ ...prev, [org]: true }));
      } else {
        closeAccordion(org);
      }
      return acc;
    }, {});
  }, [workspaces, workspace, closeAccordion]);

  const toggleDrawer = (open) => (event) => {
    setOpen(open);
  };
  const toggleAppraiserMode = () => {
    setAppraiserMode((prev) => !prev);
    navigate('/dashboard', { replace: true });
  };
  const handleLogout = () => {
    setUser(null);
    logout();
    navigate('/login', { replace: true });
  };
  const handleWorkspaceChange = (newWorkspace) => {
    setWorkspace(newWorkspace);
    sessionStorage.setItem('workspace', JSON.stringify(newWorkspace));
    setExpanded((prev) => {
      const newState = {};
      for (const key in prev) {
        newState[key] = key === newWorkspace.name;
      }
      return newState;
    });
    if (newWorkspace.organization_type !== workspace?.organization_type) {
      navigate('/dashboard', { replace: true });
    }
  };
  const handleNavSelection = (linkDestination) => {
    navigate(linkDestination);
    toggleAccordion('left');
  };

  const ownerItems = (
    <>
      {/* <ListItemButton>
            <ListItemText primary="Messages" />
          </ListItemButton> */}
      {/* <Link to="/subscriptions">
        <ListItemButton>Subscriptions</ListItemButton>
      </Link> */}
      <Link to="/workflows">
        <ListItemButton>Workflows</ListItemButton>
      </Link>
      {/* <Link to="/api-keys">
        <ListItemButton>API Keys</ListItemButton>
      </Link> */}
    </>
  );

  const appraiserItems = (
    <>
      <Link to="/add-sale">
        <ListItemButton>Add Sale Records</ListItemButton>
      </Link>
      <Link to="/validate-data">
        <ListItemButton>Validate Artwork/Artist Data</ListItemButton>
      </Link>
    </>
  );

  const NavItem = ({ linkDestination, label }) => {
    const isActive = currentLocation.includes(linkDestination.substring(1));
    return (
      <>
        <ListItemButton
          key={linkDestination}
          onClick={() => handleNavSelection(linkDestination)}
          className={`dropdown-button-container ${isActive ? 'selected' : ''}`}
          sx={{ height: 51 }}
        >
          <ListItemText
            className={`dropdown-button`}
            sx={{ fontFamily: 'inherit' }}
          >
            {label}
          </ListItemText>
        </ListItemButton>
      </>
    );
  };

  const appraiserNavigation = (
    <ClickAwayListener onClickAway={() => closeAccordion('left')}>
      <Accordion
        expanded={expanded.left}
        disableGutters
        sx={{
          backgroundColor: 'inherit',
          color: 'inherit',
          minHeight: 0,
          overflow: 'visible',
          position: 'absolute',
          left: 20,
          top: 0,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
        }}
        className="left navbar-text appraiser-navigation"
        id="appraiser-navigation"
      >
        <AccordionSummary
          sx={{ minHeight: 0 }}
          onClick={() => toggleAccordion('left')}
        >
          <span className="navbar-text">
            Appraisal Bureau | Appraiser Workspace
          </span>
          <ReactSVG
            src={expanded['left'] ? ChevronUp : ChevronDown}
            className="icon"
          />
        </AccordionSummary>
        <AccordionDetails
          className="appraiser-nav-details"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: 300,
          }}
        >
          <NavItem linkDestination="/dashboard" label="Dashboard" />
          <NavItem linkDestination="/add-sale" label="Add Sale Records" />
          <NavItem
            linkDestination="/validate-data"
            label="Validate Artwork/Artist Data"
          />
        </AccordionDetails>
      </Accordion>
    </ClickAwayListener>
  );

  const workspaceDropdown = (
    <ClickAwayListener onClickAway={() => closeAccordion('left')}>
      <Accordion
        expanded={expanded.left}
        disableGutters
        sx={{
          backgroundColor: 'inherit',
          color: 'inherit',
          minHeight: 0,
          overflow: 'visible',
          position: 'absolute',
          left: 40,
          top: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
        className="left navbar-text"
        id="workspace-dropdown"
      >
        <AccordionSummary
          sx={{ minHeight: 0 }}
          onClick={() => toggleAccordion('left')}
        >
          <span className="navbar-text">
            {workspace?.organization_name
              ? `${workspace.organization_name} `
              : `${displayUserInfo(user)}'s Portfolios`}
            | {workspace?.name}
          </span>
          <ReactSVG
            src={expanded['left'] ? ChevronUp : ChevronDown}
            className="icon"
          />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            position: 'absolute',
            right: 0,
          }}
          className="workspace-dropdown-details"
        >
          {Object.keys(organizations).map((orgName) => (
            <Accordion
              key={orgName}
              className="navbar-text org-accordion"
              expanded={expanded[orgName]}
              disableGutters
            >
              <AccordionSummary
                sx={{ minHeight: 0 }}
                onClick={() => toggleAccordion(orgName)}
                className="sub-accordion"
              >
                <p>{orgName}</p>
                <ReactSVG
                  src={expanded[orgName] ? ChevronUp : ChevronDown}
                  className="icon"
                />
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                {organizations[orgName].map((ws) => (
                  <ListItemButton
                    key={ws.id}
                    value={ws.id}
                    onClick={() => handleWorkspaceChange(ws)}
                    className="dropdown-button-container"
                  >
                    <ListItemText
                      className={`dropdown-button ${workspace?.name === ws.name ? 'selected' : ''}`}
                      sx={{ fontFamily: 'inherit' }}
                    >
                      {ws.name}
                    </ListItemText>
                  </ListItemButton>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
          <AccordionDetails className="org-accordion">
            <ListItemButton onClick={() => navigate('/onboard-workspace')}>
              <ListItemText
                className="dropdown-button"
                sx={{ fontFamily: 'inherit' }}
              >
                Create New Workspace
              </ListItemText>
            </ListItemButton>
          </AccordionDetails>
          <AccordionDetails className="org-accordion">
            <ListItemButton onClick={() => navigate('/onboard-organization')}>
              <ListItemText
                className="dropdown-button"
                sx={{ fontFamily: 'inherit' }}
              >
                Create New Organization
              </ListItemText>
            </ListItemButton>
          </AccordionDetails>
        </AccordionDetails>
      </Accordion>
    </ClickAwayListener>
  );

  return (
    <div className="navbar" data-testid="navbar">
      {appraiserMode && appraiserNavigation}
      {!appraiserMode && workspaceDropdown}
      <ClickAwayListener onClickAway={() => closeAccordion('right')}>
        <Accordion
          disableGutters
          expanded={expanded['right']}
          sx={{
            backgroundColor: 'inherit',
            color: 'inherit',
            minHeight: 0,
            overflow: 'clip',
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          className="right navbar-text"
          id="user-dropdown"
        >
          <AccordionSummary
            sx={{ minHeight: 0 }}
            onClick={() => toggleAccordion('right')}
          >
            <span className="navbar-text">
              {displayUserInfo(user)}
              <ReactSVG
                src={expanded['right'] ? ChevronUp : ChevronDown}
                className="icon"
              />
            </span>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {isAppraiser() && (
              <ListItemButton
                className="dropdown-button"
                onClick={toggleAppraiserMode}
              >
                <ListItemText>Toggle Appraiser Mode</ListItemText>
              </ListItemButton>
            )}
            <ListItemButton className="dropdown-button" onClick={handleLogout}>
              <ListItemText sx={{ fontFamily: 'inherit' }}>Logout</ListItemText>
            </ListItemButton>
          </AccordionDetails>
        </Accordion>
      </ClickAwayListener>
      <FiMenu
        onClick={toggleDrawer(!open)}
        className="hamburger-menu right icon"
      />

      <Drawer
        anchor="right"
        className="hamburger-menu-container"
        variant="temporary"
        open={open}
        onClose={toggleDrawer(false)}
      >
        {isOwner(workspace) && (
          <>
            <Accordion
              expanded={expanded.hamburger}
              disableGutters
              sx={{
                backgroundColor: 'inherit',
                color: 'inherit',
                overflow: 'visible',
              }}
            >
              <AccordionSummary onClick={() => toggleAccordion('hamburger')}>
                <span>{'Workspace'}</span>
                <ReactSVG
                  src={expanded['hamburger'] ? ChevronUp : ChevronDown}
                  className="icon"
                />
              </AccordionSummary>
              <AccordionDetails sx={{ paddingLeft: 2, boxShadow: 'none' }}>
                {Object.keys(organizations).map((orgName) => (
                  <Accordion
                    key={orgName}
                    expanded={expanded[orgName]}
                    disableGutters
                  >
                    <AccordionSummary
                      sx={{ minHeight: 0 }}
                      onClick={() => toggleAccordion(orgName)}
                      className="sub-accordion"
                    >
                      <p>{orgName}</p>
                      <ReactSVG
                        src={expanded[orgName] ? ChevronUp : ChevronDown}
                        className="icon"
                      />
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        paddingLeft: 3,
                      }}
                    >
                      {organizations[orgName].map((ws) => (
                        <ListItemButton
                          key={ws.id}
                          value={ws.id}
                          onClick={() => handleWorkspaceChange(ws)}
                          className="dropdown-button-container"
                        >
                          <ListItemText
                            className={`dropdown-button mobile-only ${workspace.name === ws.name ? 'selected' : ''}`}
                            sx={{ fontFamily: 'inherit', color: 'black' }}
                          >
                            {ws.name}
                          </ListItemText>
                        </ListItemButton>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </AccordionDetails>
            </Accordion>
          </>
        )}
        <div
          onClick={toggleDrawer(false)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '18px',
            paddingTop: '9px',
          }}
        >
          <Link to="/dashboard">
            <ListItemButton onClick={toggleDrawer(false)}>
              Dashboard
            </ListItemButton>
          </Link>
          <Link to="/portfolio">
            <ListItemButton>Portfolio</ListItemButton>
          </Link>
          {appraiserMode
            ? appraiserItems
            : isOwner(workspace)
              ? ownerItems
              : null}
          <Link to="/settings">
            <ListItemButton onClick={toggleDrawer(false)}>
              Settings
            </ListItemButton>
          </Link>
          <ListItemButton onClick={handleLogout}>Logout</ListItemButton>
        </div>
      </Drawer>
    </div>
  );
}

export default Navbar;
